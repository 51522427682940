<template>
    <md-dialog :md-fullscreen="false" :md-active="show">
        <div style="position:relative;">
            <md-button @click="close" class="closeX">
                X
            </md-button>
        </div>
        <div class="text-center mt16 ml16 mr16 mb16">
            <img :src="require('@assets/images/icon_passenger_taken.svg')" />
            <div class="text-black uppercase">
                {{
                    edit ? $t('modals.addPassengerToBlackList') : 'Czarna lista'
                }}
            </div>
        </div>

        <div class="text-center">
            <p class="pl16 pr16">
                {{ edit ? $t('modals.infoMessageBlackList') : description }}
            </p>

            <label v-if="edit" class="pure-material-textfield-outlined mt16">
                <textarea
                    placeholder=" "
                    v-model="description"
                    rows="2"
                    cols="33"
                />
                <span>{{ $t('modals.writeFewWords') }}</span>
            </label>
        </div>

        <small v-if="edit">
            {{ 500 - description.length >= 0 ? 500 - description.length : 0 }} /
            500
        </small>

        <md-button
            v-if="edit"
            class="mt16"
            :class="{
                'btn-default-blue': !$v.description.$invalid,
                'btn-default-action': $v.description.$invalid
            }"
            style="margin-left:0;"
            @click="send"
        >
            {{
                getBlackList.blacklisted
                    ? $t('modals.updateReport')
                    : $t('modals.reportPassenger')
            }}
        </md-button>
    </md-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        inquiryUUID: {
            type: String,
            default: ''
        },

        phone: {
            type: String,
            default: ''
        },

        edit: {
            type: Boolean,
            default: true
        }
    },

    validations: {
        description: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(500)
        }
    },

    watch: {
        show() {
            if (this.show && this.getBlackList.blacklisted) {
                this.description = this.getBlackList.desc;
            }
        }
    },

    data() {
        return {
            description: ''
        };
    },

    computed: {
        ...mapGetters({
            getBlackList: 'content/blackList'
        })
    },

    methods: {
        ...mapActions({
            addToBlacklist: 'flotea/addToBlacklist',
            blacklist: 'flotea/blacklist',
            featchBlacklist: 'content/getBlacklist'
        }),

        async send() {
            const params = {
                desc: this.description,
                inquiryUUID: this.inquiryUUID,
                phone: this.phone
            };

            if (this.description.length >= 10) {
                await this.addToBlacklist(params);
                await this.featchBlacklist(this.inquiryUUID);
                this.close();
            }
        },

        close() {
            this.$emit('close');
        }
    }
};
</script>
