<template>
    <div>
        <md-dialog
            :md-active="show"
            :md-fullscreen="false"
            :md-click-outside-to-close="false"
        >
            <md-dialog-title>
                <div v-html="$t('modals.areYouTakingThisPassenger')" />
            </md-dialog-title>
            <div class="c707070" v-html="$t('modals.infoMessageTake')" />
            <div v-if="partnerPrice">
                {{ partnerPrice }}
                {{ $t('global.currency') }} / {{ partnerPrice * 10
                }}{{ $t('header.shortcutPoints') }}
            </div>
            <div v-else>
                {{ currentUser.default_take_price }}
                {{ $t('global.currency') }} /
                {{ currentUser.default_take_price * 10
                }}{{ $t('header.shortcutPoints') }}
            </div>

            <md-dialog-actions class="mt20">
                <md-button
                    class="md-primary"
                    @click="$emit('carrierDontGetPassenger')"
                >
                    {{ $t('modals.close') }}
                </md-button>
                <md-button
                    v-if="show == true"
                    class="default-button btn-get-passenger-small"
                    @click="$emit('carrierGetPassenger')"
                >
                    {{ $t('modals.takeHim') }}
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            partnerPrice: 'content/partnerPrice'
        })
    }
};
</script>
