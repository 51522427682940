<template>
    <div class="seed-dial">
        <div class="seed-dial-fixed">
            <md-speed-dial :class="bottomPosition">
                <md-speed-dial-target class="md-primary">
                    <img src="@assets/images/icon_hamburger.svg" />
                </md-speed-dial-target>

                <md-speed-dial-content>
                    <md-button
                        class="md-icon-button"
                        @click="$emit('openModal', 'deposit')"
                    >
                        <img src="@assets/images/icon-zaliczka.svg" />
                    </md-button>

                    <md-button
                        class="md-icon-button"
                        @click="$emit('openModal', 'incorrectPhoneNumber')"
                    >
                        <img src="@assets/images/icon_incorrect_number.svg" />
                    </md-button>

                    <md-button
                        class="md-icon-button"
                        @click="$emit('openModal', 'passengerPickedUp')"
                    >
                        <img src="@assets/images/icon_alarm.svg" />
                    </md-button>

                    <md-button
                        class="md-icon-button"
                        @click="$emit('openModal', 'blackList')"
                    >
                        <img src="@assets/images/icon_passenger_taken.svg" />
                    </md-button>
                </md-speed-dial-content>
            </md-speed-dial>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PositionDirection',
    data: () => ({
        bottomPosition: 'md-bottom-right'
    })
};
</script>

<style>
.example {
    min-height: 300px;
}

.seed-dial-fixed {
    position: fixed;
    bottom: 3%;
}
.md-speed-dial.md-bottom-right {
    right: -32px;
}
</style>
