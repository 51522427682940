<template>
    <div
        v-if="!isTakenOther"
        :class="{
            'getDeposit hr':
                !passengerIsCalling || depositWait || depositSuccess
        }"
    >
        <span
            v-if="!depositWait && !depositSuccess && !passengerIsCalling"
            class="c9c9c9c"
        >
            {{ $t('route.generateDeposit') }}
            <span class="pointer">
                <img
                    @click="tooltipActive = !tooltipActive"
                    src="@assets/images/icon-information.png"
                    alt="info"
                />
                <md-tooltip
                    md-direction="bottom"
                    :md-active.sync="tooltipActive"
                >
                    <span
                        ng-bind-html="msg | unsafeSpecial"
                        v-html="$t('route.depositInfoMessage')"
                    />
                </md-tooltip>
            </span>
        </span>

        <div v-if="depositWait || depositSuccess">
            <span class="w49p dib text-left c9c9c9c">{{
                $t('route.deposit')
            }}</span>
            <span class="w49p dib text-right c9c9c9c"
                >{{ depositPrice }} {{ $t('global.currency') }}</span
            >
        </div>
        <div v-if="depositSuccess" class="w100p  mt10 text-left">
            <span class="c9c9c9c fs13">
                <img
                    src="@assets/images/icon-zaliczka-2.png"
                    alt="wait for payment"
                    style="margin-left: 3px;"
                />
                {{ $t('route.depositPaid') }}
            </span>
        </div>
        <div v-else-if="depositWait" class="w100p mt10 c9c9c9c text-left">
            <span class="c9c9c9c fs13 ">
                <img
                    :src="require('@assets/images/icon-zaliczka.svg')"
                    alt="wait for payment"
                    style="margin-left: 3px;"
                />
                {{ $t('route.waitingPayment') }}
            </span>
        </div>

        <md-dialog :md-fullscreen="false" :md-active="show">
            <div style="position:relative;">
                <md-button @click="$emit('close')" class="closeX">
                    X
                </md-button>
            </div>
            <div class="text-center mt16 ml16 mr16 mb16">
                <img :src="require('@assets/images/icon-zaliczka.svg')" />
                <div class="text-black uppercase">
                    {{ $t('route.generateDepositSection') }}
                </div>
            </div>

            <div class="mt20">
                <input
                    id="deposit_price"
                    v-model="depositPrice"
                    @focus="clearInput"
                    type="number"
                    class="form-input"
                    placeholder="Kwota zaliczki"
                    :disabled="depositSent"
                />
                <span class="zl">{{ $t('global.currency') }}</span>
            </div>

            <md-button
                v-if="!depositSent"
                @click="beforeSend"
                class="btn-default-blue mt16"
                :class="{
                    'btn-default-blue': !$v.$invalid,
                    'btn-default-action': $v.$invalid
                }"
                style="margin-left:0;"
            >
                <div v-if="!loading">
                    {{
                        !depositSent
                            ? $t('route.sendDeposit')
                            : $t('route.sent')
                    }}
                </div>

                <img
                    v-if="loading"
                    src="@assets/images/loader.gif"
                    alt="loader"
                    title="loader"
                />
            </md-button>

            <div
                v-if="depositSent || depositWait || depositSuccess"
                class="cadc247 m10 mt16 text-center"
            >
                {{ $t('route.paymentRequestHasBeenSent') }}
            </div>
        </md-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { required, between } from 'vuelidate/lib/validators';
import moment from 'moment';
import config from '@config';
import qs from 'qs';
const io = require('socket.io-client');
const socket = io(`${config.socketUrl}`);

export default {
    validations: {
        depositPrice: {
            required,
            between: between(1, 500)
        }
    },

    props: {
        canCall: {
            type: Boolean,
            default: false
        },

        depositWaitForPayment: {
            type: String,
            default: null
        },

        isTakenOther: {
            type: Boolean,
            default: false
        },

        show: {
            type: Boolean,
            default: false
        },

        passengerIsCalling: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            tooltipActive: false,
            loading: false,
            depositSent: false,
            depositWait: false,
            depositSuccess: false,
            depositPrice: 0
        };
    },

    watch: {
        depositWaitForPayment(e) {
            if (e) {
                this.getDeposit();
            }
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            route: 'content/routeDetails'
        })
    },

    methods: {
        ...mapActions({
            getDepositApp: 'content/getDepositApp',
            sendDeposit: 'content/sendDeposit'
        }),

        async getDeposit() {
            const { inquiryUUID } = this.$route.params;
            const { carrierUUID } = this.currentUser;

            const { deposit } = await this.getDepositApp({
                carrierUUID,
                inquiryUUID
            });

            if (deposit) {
                this.depositSent = true;
                this.depositPrice = deposit.price;
                if (
                    deposit.status === 'COMPLETED' ||
                    deposit.status === 'COMPLETED_PASS_CREDIT'
                ) {
                    this.depositSuccess = true;
                } else {
                    this.depositWait = true;
                }
            }
        },

        beforeSend() {
            if (!this.$v.$invalid) {
                this.send();
            }
        },

        async send() {
            this.loading = true;
            const { inquiryUUID } = this.$route.params;
            const date = moment(String(this.route.requested_at)).format(
                'DD.MM.YYYY'
            );

            const params = qs.stringify({
                uuid: this.currentUser.carrierUUID,
                url: window.location.href,
                request: inquiryUUID,
                price: this.depositPrice,
                phone: this.route.phone,
                desc: this.route.from + ' - ' + this.route.to + ' ' + date,
                app: 1
            });

            const response = await this.sendDeposit(params);

            if (response) {
                this.depositSent = true;
                this.loading = false;
                this.depositWait = true;
                this.depositSent = true;

                socket.disconnect();
                socket.connect();
                const msg = [{ cmd: 'depo' }];

                socket.emit('for_server', {
                    data: msg,
                    request_uuid: inquiryUUID,
                    uuid: this.currentUser.carrierUUID + '.' + inquiryUUID
                });
            }
        },

        clearInput() {
            this.depositPrice = '';
        }
    },

    created() {
        this.getDeposit();
    }
};
</script>
