<template>
    <md-dialog :md-fullscreen="false" :md-active="show">
        <div style="position:relative;">
            <md-button @click="close" class="closeX">
                X
            </md-button>
        </div>
        <div class="text-center mt16 ml16 mr16 mb16">
            <img :src="require('@assets/images/icon_alarm.svg')" />
            <div class="text-black uppercase">
                {{ $t('modals.passengerBanned') }}
            </div>
        </div>

        <div class="text-left">
            <p class="pl16 pr16">
                {{ $t('modals.infoMessagePassengerBanned') }}
            </p>

            <md-button
                @click="setReport"
                class="mt16"
                :class="{
                    'btn-default-blue': !reported,
                    'btn-default-action': reported
                }"
                style="margin-left:0;"
            >
                {{ $t('modals.reportPassenger') }}
            </md-button>
        </div>
    </md-dialog>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        inquiryUUID: {
            type: String,
            default: ''
        },
        reported: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions({
            setRouteReport: 'content/setRouteReport'
        }),

        close() {
            this.$emit('close');
        },

        async setReport() {
            const params = {
                type: 'take',
                inquiryUUID: this.inquiryUUID
            };

            if (!this.reported) {
                await this.setRouteReport(params);

                this.$emit('reportedTake', true);
                this.close();
            }
        }
    }
};
</script>
