<template>
    <div class="getDeposit hr">
        <span class="c9c9c9c">
            Pasażer dzwoni z aplikacji mobilnej
            <!-- <span class="pointer">
                <img
                    @click="tooltipActive = !tooltipActive"
                    src="@assets/images/icon-information.png"
                    alt="info"
                />
                <md-tooltip
                    md-direction="bottom"
                    :md-active.sync="tooltipActive"
                >
                    <span
                        ng-bind-html="msg | unsafeSpecial"
                        v-html="$t('route.depositInfoMessage')"
                    />
                </md-tooltip>
            </span> -->
        </span>

        <div class="bar">
            <div class="progress-bar c9c9c9c">
                <span>Potwierdź wyjazd {{ timeToWait }}</span>
                <md-progress-bar
                    md-mode="determinate"
                    :md-value="progressWidth"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        timeToWait: {
            type: String,
            default: '00:00'
        },

        progressWidth: {
            type: Number,
            default: 100
        }
    },

    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
.bar {
    position: relative;
    margin-top: 50px;
    margin-bottom: -15px;
}
.progress-bar {
    width: 70%;
    span {
        padding: 5px;
        display: flex;
        justify-content: center;
    }
}
</style>
