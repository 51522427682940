<template>
    <md-card id="route">
        <md-card-header class="db">
            <md-card-header-text>
                <div
                    class="md-layout-item md-subhead md-xsmall-size-50 text-right"
                >
                    <div class="absolut-wrapper">
                        <div class="row">
                            <div class="text-center" style="padding-left:15px">
                                <img
                                    :src="
                                        require('@assets/images/icon_red_eye.svg')
                                    "
                                />
                                <small class="text-red">{{ views }}</small>
                            </div>
                            <div
                                v-if="views > 5"
                                class="text-right text-red f8"
                            >
                                {{ $t('route.hotOffer') }}
                            </div>
                        </div>

                        <div
                            v-if="blackList.blacklisted"
                            @click="openModal('blackListDisplay')"
                            class="warnings text-center mt16 pointer"
                        >
                            <img
                                :src="
                                    require('@assets/images/icon_warning.svg')
                                "
                            />
                            <div class="text-red f8">
                                {{ $t('route.attention').substring(0, 12) }}
                            </div>
                        </div>

                        <div
                            v-if="route.report_bad_phone || reportedPhone"
                            class="warnings text-center mt16"
                        >
                            <img
                                :src="
                                    require('@assets/images/icon_incorrect_phone_red.svg')
                                "
                            />
                            <div class="text-red f8">
                                {{
                                    $t('route.incorrectPhoneNumber').substring(
                                        0,
                                        12
                                    )
                                }}
                            </div>
                        </div>

                        <div
                            v-if="route.report_take || reportedTake"
                            class="warnings text-center mt16"
                        >
                            <img
                                :src="
                                    require('@assets/images/icon_taken_red.svg')
                                "
                            />
                            <div class="text-red f8">
                                {{ $t('route.taken').substring(0, 12) }}
                            </div>
                        </div>
                    </div>
                </div>
            </md-card-header-text>
            <md-card-header-text>
                <div class="md-layout-item md-subhead md-xsmall-size-100">
                    <h2 class="uppercase light">
                        {{ $t('route.request') }}

                        <strong class="light">
                            {{
                                isPartner
                                    ? $t('route.partner')
                                    : $t('route.passenger')
                            }}
                        </strong>
                        <strong v-if="passengerVIP" class="cadc247">VIP</strong>
                    </h2>
                    <small
                        >{{
                            $formatDate(
                                route.created_at,
                                $i18n.locale,
                                'DD.MM.YYYY HH:mm'
                            )
                        }}
                        {{ route.created_at_format }}</small
                    >
                </div>
            </md-card-header-text>
        </md-card-header>
        <md-card-content class="flex">
            <div
                class="md-layout-item md-subhead md-xsmall-size-15 text-left mw40"
            >
                <img src="@assets/images/icon-marker.svg" alt="from" />
            </div>
            <div class="md-layout-item md-subhead md-xsmall-size-85 text-left">
                {{ route.from }}
            </div>
        </md-card-content>
        <md-card-content class="flex">
            <div
                class="md-layout-item md-subhead md-xsmall-size-15 text-left mw40"
            >
                <img src="@assets/images/icon-marker.svg" alt="to" />
            </div>
            <div class="md-layout-item md-subhead md-xsmall-size-85 text-left">
                {{ route.to }}
            </div>
        </md-card-content>
        <md-card-content class="flex">
            <div
                class="md-layout-item md-subhead md-xsmall-size-15 text-left mw40"
            >
                <img src="@assets/images/icon-calendar.svg" alt="from" />
            </div>
            <div class="md-layout-item md-subhead md-xsmall-size-85 text-left">
                {{
                    $formatDate(route.requested_at, $i18n.locale, 'DD.MM.YYYY')
                }}
            </div>
        </md-card-content>
        <md-card-content class="flex">
            <div
                class="md-layout-item md-subhead md-xsmall-size-15 text-left mw40"
            >
                <img src="@assets/images/icon-person.svg" alt="from" />
            </div>
            <div class="md-layout-item md-subhead md-xsmall-size-85 text-left">
                {{ $t('route.numberOfPassengers') }} {{ route.count }}
            </div>
        </md-card-content>
        <md-card-content class="flex">
            <div
                class="md-layout-item md-subhead md-xsmall-size-15 text-left mw40"
            >
                <img
                    v-if="isLocked"
                    src="@assets/images/icon_phone_locked.svg"
                    alt="from"
                />
                <img v-else src="@assets/images/icon_phone.svg" alt="from" />
            </div>
            <div
                class="md-layout-item md-subhead md-xsmall-size-85 text-left mw40"
            >
                {{ route.phone }}
            </div>
        </md-card-content>

        <md-card-content class="flex">
            <attributes-icons class="flex" :attributes="route.attributes" />
        </md-card-content>

        <md-card-actions
            class="md-card-center hr pt10 mt10"
            style="padding-top:16px;"
        >
            <md-button
                v-if="canCall"
                class="default-button btn-call"
                @click="checkCreditAndCall"
            >
                <img
                    :src="require('@assets/images/icon-call.svg')"
                    alt="call"
                />
                &nbsp;&nbsp;{{ iCall ? route.phone : $t('route.call') }}
            </md-button>
            <md-button
                v-if="!canCall && disabledCall"
                class="default-button disabled-button"
            >
                <img
                    :src="require('@assets/images/icon-call-2.svg')"
                    alt="call"
                />
                &nbsp;&nbsp;{{ $t('route.call') }}
            </md-button>

            <md-button
                v-if="calling && !canCall"
                class="default-button disabled-button"
            >
                <img
                    :src="require('@assets/images/icon-call-2.svg')"
                    alt="call"
                />
                &nbsp;&nbsp;{{ $t('route.callLater') }}
                <span id="counter">
                    {{ time }}
                </span>
            </md-button>
            <md-progress-bar
                v-if="calling && !canCall"
                class="countdown-line"
                :style="{ width: 75 + '%' }"
                :md-value="progressWidth"
            />

            <md-button
                v-if="canTake"
                class="default-button btn-get-passenger-relative"
                @click="checkCreditAndGetPassenger"
            >
                <img
                    :src="require('@assets/images/icon_take.svg')"
                    alt="call"
                />
                &nbsp;&nbsp; {{ $t('route.take') }}
            </md-button>
            <md-button
                v-else-if="!canTake && disabledTake"
                class="default-button disabled-button"
            >
                <img
                    :src="require('@assets/images/icon_take_gray.svg')"
                    alt="call"
                />
                &nbsp;&nbsp; {{ $t('route.take') }}
            </md-button>
            <md-button v-if="isTaken" class="default-button btn-taken">
                <img src="@assets/images/icon-check.png" alt="call" />
                &nbsp;&nbsp; {{ $t('route.takenByCarrier') }}
            </md-button>
        </md-card-actions>
        <md-card-actions
            v-if="offerExpired && !isTakenOther"
            class="md-card-center"
            md-alignment="left"
        >
            <div class="takenByCarrier">
                {{ $t('route.offerExpiredMesage') }}
            </div>
        </md-card-actions>
        <md-card-actions
            v-if="showTakenMessage"
            class="md-card-center"
            md-alignment="left"
        >
            <div class="takenByCarrier">
                {{ passengerHasBeenTakenBy }}
            </div>
        </md-card-actions>

        <passenger-call-section
            v-if="passengerIsCalling"
            :timeToWait="timeToWait"
            :progressWidth="passengerCallProgressWidth"
        />

        <deposit-section
            v-if="!offerExpired"
            :show="showDepositModal"
            @close="() => (showDepositModal = false)"
            :canCall="canCall"
            :isTakenOther="isTakenOther"
            :depositWaitForPayment="depositWaitForPayment"
            :passengerIsCalling="passengerIsCalling"
        />

        <md-card-actions class="hr mt5 md-card-center">
            <div class="mt16 mb16" style="width:75%; position:relative;">
                <div>
                    <md-button class="c232332" @click="openModal('deposit')">{{
                        $t('route.generateDepositSection')
                    }}</md-button>
                </div>

                <div>
                    <md-button
                        class="c232332"
                        @click="openModal('incorrectPhoneNumber')"
                        >{{ $t('route.incorrectPhoneNumber') }}</md-button
                    >
                </div>

                <div>
                    <md-button
                        class="c232332"
                        @click="openModal('passengerPickedUp')"
                    >
                        {{ $t('route.taken') }}</md-button
                    >
                </div>

                <div>
                    <md-button class="c232332" @click="openModal('blackList')">
                        {{ $t('route.blackList') }}</md-button
                    >
                </div>

                <seed-dial @openModal="openModal" />
            </div>
        </md-card-actions>
        <md-card-actions class="hr md-card-center">
            <div class="mb40" style="width:75%; position:relative;">
                <tips class="mt40" />
            </div>
        </md-card-actions>

        <after-call-modal
            :show="openAfterCallModall"
            @carrierDontGetPassenger="carrierDontGetPassenger"
            @carrierGetPassenger="checkCreditAndGetPassenger"
        />

        <too-little-credit-modal
            :show="tooLittleCredit"
            :carrierUUID="carrierUUID"
            @closeTooLittleCredit="() => (tooLittleCredit = false)"
        />

        <passenger-picked-up-modal
            :show="showPassengerPickedUpModal"
            @close="() => (showPassengerPickedUpModal = false)"
            :inquiryUUID="inquiryUUID"
            :reported="route.report_take || reportedTake"
            @reportedTake="e => (reportedTake = e)"
        />

        <incorrect-phone-number-modal
            :show="showIncorrectPhoneNumberModal"
            @close="() => (showIncorrectPhoneNumberModal = false)"
            :inquiryUUID="inquiryUUID"
            :reported="route.report_bad_phone || reportedPhone"
            @reportedPhone="e => (reportedPhone = e)"
        />

        <black-list-modal
            :show="showBlackListModal"
            :inquiryUUID="inquiryUUID"
            :phone="route.phone"
            @close="() => (showBlackListModal = false)"
            :edit="blackListEditMode"
        />

        <snackbar :show="info" :text="message" />
        <snackbar :show="showCalling" :text="passengerIsTalkingMessage" />
        <snackbar :show="showTakenMessage" :text="passengerHasBeenTakenBy" />
        <snackbar :show="offerExpired" :text="$t('route.offerExpiredMesage')" />
        <md-card-actions class="mt5 md-card-center">
            <small class="c9c9c9c ml16">
                {{ $t('route.inquiryID') }} {{ inquiryUUID.slice(0, 8) }}</small
            >
        </md-card-actions>
    </md-card>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import config from '@config';
import AfterCallModal from '../modals/afterCallModal';
import TooLittleCreditModal from '../modals/tooLittleCreditModal';
import IncorrectPhoneNumberModal from '../modals/incorrectPhoneNumberModal';
import PassengerPickedUpModal from '../modals/passengerPickedUpModal';
import BlackListModal from '../modals/blackListModal';
import DepositSection from './depositSection';
import Snackbar from '../elements/Snackbar';
import Tips from '../elements/Tips';
import SeedDial from '../elements/SeedDial';
import AttributesIcons from '../elements/AttributesIcons';
import PassengerCallSection from './passengerCallSection';

const io = require('socket.io-client');
const socket = io(`${config.socketUrl}`, {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000
});

export default {
    components: {
        AfterCallModal,
        TooLittleCreditModal,
        BlackListModal,
        IncorrectPhoneNumberModal,
        PassengerPickedUpModal,
        Snackbar,
        DepositSection,
        Tips,
        SeedDial,
        AttributesIcons,
        PassengerCallSection
    },

    data() {
        return {
            passengerVIP: false,
            passengerIsCalling: false,
            timeToWait: '',
            blackListEditMode: false,
            reportedPhone: false,
            reportedTake: false,
            info: false,
            message: '',
            showPassengerPickedUpModal: false,
            showDepositModal: false,
            showBlackListModal: false,
            showIncorrectPhoneNumberModal: false,
            openAfterCallModall: false,
            attributes: [1, 0, 1, 1, 1, 1],
            depositWaitForPayment: '',
            canTake: false,
            disabledTake: true,
            disabledCall: true,
            calling: false,
            iCall: false,
            canCall: false,
            isTaken: false,
            isTakenOther: false,
            showCalling: false,
            showTakenMessage: false,
            date: moment(300 * 1000),
            time: '',
            progressWidth: 100,
            passengerCallProgressWidth:100,
            takenByCarrier: '',
            tooLittleCredit: false,
            offerExpired: false,
            carrierUUID: '',
            inquiryUUID: '',
            cmdCall: 'call',
            cmdTake: 'take'
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            route: 'content/routeDetails',
            carrier: 'content/carrierDetails',
            views: 'content/views',
            deposit: 'content/deposit',
            blackList: 'content/blackList'
        }),

        isLocked() {
            if (this.route.phone) {
                return this.route.phone.includes('...') ? true : false;
            } else {
                return false;
            }
        },

        actions() {
            return (this.canTake || this.isTaken) && !this.offerExpired;
        },

        passengerHasBeenTakenBy() {
            return ` ${this.$t('global.passengerHasBeenTakenBy')} ${
                this.takenByCarrier
            }`;
        },

        passengerIsTalkingMessage() {
            return this.$t('global.carrierCallsPassenger');
        },

        isPartner() {
            const { partner } = this.route;

            if (
                partner === '00000000-0000-0000-0000-000000000000' ||
                partner == null
            ) {
                return false;
            } else {
                return true;
            }
        }
    },

    methods: {
        ...mapActions({
            getRoute: 'content/getRoute',
            getPhone: 'content/getPhone',
            getCarrier: 'content/getCarrier',
            getViews: 'content/getViews',
            getCalled: 'content/getCalled',
            getAnyCall: 'content/getAnyCall',
            returnCarrier: 'content/returnCarrier',
            getBlacklist: 'content/getBlacklist',
            getRoutePartner: 'content/getRoutePartner',
            getPassengersStatuses: 'requests/getPassengersStatuses'
        }),
        ...mapMutations({
            updateRoute: 'content/UPDATE_ROUTE_DETAILS'
        }),

        reset() {
            setTimeout(() => {
                this.info = false;
            }, 2000);
        },

        openModal(e) {
            if (e === 'blackListDisplay') {
                this.showBlackListModal = true;
                this.blackListEditMode = false;

                return;
            }

            if ((this.canTake || this.isTaken) && !this.offerExpired) {
                if (e === 'passengerPickedUp') {
                    this.showPassengerPickedUpModal = true;
                }

                if (e === 'incorrectPhoneNumber') {
                    this.showIncorrectPhoneNumberModal = true;
                }

                if (e === 'blackList') {
                    this.showBlackListModal = true;
                    this.blackListEditMode = true;
                }

                if (e === 'deposit' && this.iCall) {
                    this.showDepositModal = true;
                } else if (e === 'deposit' && !this.isTaken) {
                    this.info = true;
                    this.message = this.$t('route.infoMessageTake');
                    this.reset();
                }

                return;
            }

            if (!this.isTakenOther && this.canCall && !this.offerExpired) {
                this.info = true;
                this.message = this.$t('route.infoMessage');
                this.reset();
            } else {
                this.info = true;
                this.message = this.$t('route.offerExpiredMesage');
                this.reset();
            }
        },

        carrierDontGetPassenger() {
            this.openAfterCallModall = false;
            this.isTaken = false;
        },

        async getRouteDetails() {
            const { created_at, taken_by } = this.route;

            if (moment().format(created_at) === moment().format('YYYY-MM-DD')) {
                const created_at_format = this.$t('route.today');
                const created_at = null;

                this.updateRoute({ created_at_format, created_at });
            }

            if (taken_by === this.carrierUUID) {
                this.isTaken = true;
                this.disabledTake = false;
                this.isTakenOther = false;
                this.canCall = true;
                this.iCall = true;

                await this.getPhone(this.inquiryUUID);
            } else if (typeof taken_by === 'undefined' || taken_by === null) {
                this.canCall = true;
                this.iCall = false;
                this.canTake = false;
                this.disabledTake = true;
            } else if (taken_by !== this.carrierUUID) {
                this.showTakenMessage = true;
                this.isTakenOther = true;
                this.canCall = false;
                this.disabledCall = true;
                this.canTake = false;
                this.disabledTake = true;

                const { company_name } = await this.returnCarrier(taken_by);

                this.takenByCarrier = company_name;
            }

            const { take, call, passenger_call } = await this.getCalled({
                carrierUUID: this.carrierUUID,
                inquiryUUID: this.inquiryUUID
            });

            if (taken_by === this.carrierUUID || taken_by === null) {
                if (!take & (call || passenger_call > 0)) {
                    this.iCall = true;
                    this.canCall = true;
                    this.canTake = true;

                    await this.getPhone(this.inquiryUUID);

                    // this.openAfterCallModall = true;
                }
            }

            const anyCall = await this.getAnyCall(this.inquiryUUID);

            if (!!anyCall && anyCall.carrier !== this.carrierUUID) {
                this.calling = true;
                this.canCall = false;
                this.canTake = false;
                this.disabledCall = false;
                this.disabledTake = true;
                this.showPhoneNumber(anyCall.created_at, 300);
            } else if (
                !!anyCall &&
                anyCall.carrier === this.carrierUUID &&
                anyCall.passenger_call
            ) {
                await this.getPhone(this.inquiryUUID);
                this.canCall = true;
                this.iCall = true;
                this.canTake = true;
                this.disabledTake = false;

                const now = moment(moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss.SSSS'));
                const end = moment(anyCall.passenger_call_at);
                const duration = moment.duration(now.diff(end));
                this.passengerCallToCarrier(300000 - duration.asMilliseconds());
            }
        },

        async showPhoneNumber(updated_at, t) {
            if (updated_at !== null) {
                const now = moment(
                    moment()
                        .utcOffset(0)
                        .format('YYYY-MM-DD HH:mm:ss.SSSS')
                );

                const end = moment(updated_at);
                const duration = moment.duration(now.diff(end));
                const sec = duration.asSeconds();
                this.date = moment((t - sec) * 1000);
                this.progressWidth = 100 - (sec / t) * 100;
            }

            const intvl = setInterval(() => {
                this.date = this.date.subtract(1, 'seconds');
                this.time = this.date.format('mm:ss');
                this.progressWidth -= 100 / t;

                if (this.progressWidth <= 0 || this.isTaken || this.isTakenOther) {
                    clearInterval(intvl);
                    this.canCall = true;
                    this.calling = false;
                    this.showCalling = false;
                    this.progressWidth = 100;
                    this.date = moment(t * 1000);

                    if (this.iCall) {
                        this.canTake = true;
                        this.disabledTake = false;
                    }
                }
            }, 1000);
        },

        checkCreditAndGetPassenger() {
            this.openAfterCallModall = false;
            const { default_call_price } = this.currentUser;

            if (this.currentUser.credit < default_call_price) {
                this.tooLittleCredit = true;
            } else {
                socket.disconnect();
                socket.connect();
                this.carrierGetPassenger();
            }
        },

        carrierGetPassenger() {
            const msg = [{ cmd: this.cmdTake }];

            socket.emit('for_server', {
                data: msg,
                request_uuid: this.inquiryUUID,
                uuid: this.carrierUUID + '.' + this.inquiryUUID
            });

            socket.on('for_client', async data => {
                if (data.content === `{"cmd":"${this.cmdTake}"}`) {
                    this.disabledTake = false;
                    this.canTake = false;
                    this.isTaken = true;
                    this.canCall = true;
                    this.iCall = true;
                    this.timeToWait = '';
                    this.passengerIsCalling = false;
                }
            });
        },

        checkCreditAndCall() {
            const { default_take_price } = this.currentUser;

            if (this.currentUser.credit < default_take_price) {
                this.tooLittleCredit = true;
            } else {
                this.callToPassenger();
            }
        },

        async callToPassenger() {
            const msg = [{ cmd: this.cmdCall }];

            if (!this.iCall) {
                socket.emit('for_server', {
                    data: msg,
                    request_uuid: this.inquiryUUID,
                    uuid: this.carrierUUID + '.' + this.inquiryUUID
                });

                socket.on('for_client', async data => {
                    if (data.content === `{"cmd":"${this.cmdCall}"}`) {
                        try {
                            this.iCall = true;
                            const response = await this.getPhone(
                                this.inquiryUUID
                            );
                            if (response) {
                                this.isMobile()
                                    ? (location.href =
                                          'tel:' + this.route.phone)
                                    : window.open(
                                          'tel:' + this.route.phone,
                                          'downloadIframe'
                                      );
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
            } else {
                this.isMobile()
                    ? (location.href = 'tel:' + this.route.phone)
                    : window.open('tel:' + this.route.phone, 'downloadIframe');
            }
        },

        async getMq() {
            socket.emit('for_server', {
                request_uuid: this.inquiryUUID,
                uuid: this.carrierUUID + '.' + this.inquiryUUID
            });

            socket.on('for_client', async data => {
                const { msg, content } = data;
                const routingKey = this.carrierUUID + '.' + this.inquiryUUID;
                const rabbitMqResponse = msg.fields.routingKey.split('.');

                if (content === `{"cmd":"depo_paied"}`) {
                    this.depositWaitForPayment = 'depo_paied';
                }

                if (content === `{"cmd":"passenger_call"}`) {
                    if (msg.fields.routingKey === routingKey) {
                        await this.getPhone(this.inquiryUUID);
                        this.passengerCallToCarrier(300000);
                        this.canCall = true;
                        this.iCall = true;
                        this.canTake = true;
                        this.disabledTake = false;
                        this.openAfterCallModall = true;
                    } else {
                        this.openAfterCallModall = false;
                        this.calling = true;
                        this.canCall = false;
                        this.canTake = false;
                        this.disabledCall = false;
                        this.disabledTake = true;
                        this.showPhoneNumber(null, 300);
                    }
                }

                if (content === `{"cmd":"${this.cmdCall}"}`) {
                    if (msg.fields.routingKey === routingKey) {
                        await this.getPhone(this.inquiryUUID);
                        this.canCall = true;
                        this.iCall = true;
                        this.canTake = true;
                        this.disabledTake = false;
                        this.openAfterCallModall = true;
                    } else {
                        this.openAfterCallModall = false;
                        this.calling = true;
                        this.canCall = false;
                        this.canTake = false;
                        this.disabledCall = false;
                        this.disabledTake = true;
                        this.showPhoneNumber(null, 300);
                    }
                }

                if (content === `{"cmd":"${this.cmdTake}"}`) {
                    if (msg.fields.routingKey === routingKey) {
                        this.canTake = false;
                        this.openAfterCallModall = false;
                        this.isTaken = true;
                        this.disabledTake = false;
                        this.isTakenOther = false;
                        this.canCall = true;
                        this.iCall = true;
                        this.passengerIsCalling = false;

                        await this.getPhone(this.inquiryUUID);
                    } else {

                        this.openAfterCallModall = false;
                        this.calling = false;
                        this.showTakenMessage = true;
                        this.disabledCall = true;
                        this.canCall = false;
                        this.iCall = false;
                        this.canTake = false;
                        this.disabledTake = true;

                        const { company_name } = await this.returnCarrier(
                            rabbitMqResponse.shift()
                        );

                        this.takenByCarrier = company_name;
                    }
                }

                if (content === '{"cmd":"depo"}') {
                    this.depositWaitForPayment = 'depo';
                    this.passengerIsCalling = false;
                }

                if (content === '{"cmd":"error"}') {
                    if (msg.fields.routingKey === routingKey) {
                        this.$toast.error(this.$t('global.somethingWrong'));
                    }
                }

                try {
                    var parsedResponse = JSON.parse(content);
                } catch (error) {
                    this.$toast.error('invalid json');
                }

                if (
                    parsedResponse.cmd === 'test' &&
                    msg.fields.routingKey === this.inquiryUUID
                ) {
                    if (parsedResponse.msg) {
                        this.$toast.success(parsedResponse.msg);
                    } else {
                        this.$toast.success('It works!');
                    }
                }

                this.$emit('featch');
            });
        },

        isTakenByCarrie() {
            const { taken_by } = this.route;

            if (!!taken_by || taken_by !== this.carrierUUID) {
                return false;
            } else {
                return taken_by === this.carrierUUID;
            }
        },

        async ifCurrentOffer() {
            const { take, call } = await this.getCalled({
                carrierUUID: this.carrierUUID,
                inquiryUUID: this.inquiryUUID
            });
            const { created_at } = this.route;
            const status = moment(created_at).add(22, 'hours');
            const now = moment(
                moment()
                    .utcOffset(0)
                    .format('YYYY-MM-DD HH:mm:ss.SSSS')
            );

            if (!now.isAfter(status)) {
                return true;
            } else {
                if (call === 1 || this.isTakenByCarrie()) {
                    if (take) {
                        this.disabledTake = false;
                    }
                    this.offerExpired = false;
                    return true;
                } else {
                    this.offerExpired = true;
                    return false;
                }
            }
        },

        async handleVisibilityChange() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                ) &&
                document.visibilityState === 'visible'
            ) {
                socket.disconnect();
                socket.connect();
            }
        },

        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },

        passengerCallToCarrier(time) {
            this.passengerIsCalling = true;
            const date = moment(time);
            let subtract = date;

            const intvl = setInterval(() => {
                this.timeToWait = subtract.format('mm:ss');
                subtract = subtract.subtract(1, 'seconds');

                this.passengerCallProgressWidth = (time / 300000) * 100;
                time -= 1000;

                if (this.passengerCallProgressWidth <= 0) {
                    clearInterval(intvl);
                    this.passengerCallProgressWidth = 100;
                    this.passengerIsCalling = false;
                }
            }, 1000);
        }
    },

    async created() {
        const { inquiryUUID } = this.$route.params;
        this.carrierUUID = this.currentUser.carrierUUID;
        this.inquiryUUID = inquiryUUID;

        try {
            await this.getRoute(this.inquiryUUID);
            await this.getViews({ carrierUUID: this.carrierUUID, inquiryUUID });
            await this.getBlacklist(this.inquiryUUID);
        } catch (error) {
            this.$toast.error(this.$t('route.inquiryNotExist'));
            this.$router.push({ name: 'home' });
        }

        if (await this.ifCurrentOffer()) {
            this.getMq();
            this.getRouteDetails();
        }

        this.$emit('loading', false);

        const passengersStatuses = await this.getPassengersStatuses(
            JSON.stringify([this.inquiryUUID])
        );
        this.passengerVIP = passengersStatuses[this.inquiryUUID];

        window.addEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );

        this.$gtag.config({
            cookie_prefix: 'GA_MEASUREMENT_ID',
            cookie_expires: 28 * 24 * 60 * 60,
            user_id: this.carrierUUID
        });
    }
};
</script>
<style scoped>
.flex {
    display: flex;
}
</style>
